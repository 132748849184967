body {
  background-color: #0a0907;
  color: #fff;
}

main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
  color: #fff;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
  color: #fff;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
  color: #fff;
}

.profile-image {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-box-shadow: 0 0 10px rgb(200, 200, 200);
  box-shadow: 0 0 10px rgb(200, 200, 200);

  margin: 30px;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: none;
}
.connect-button {
  background-color: black;
  color: white;
  font-weight: bold;
  border: 0px;
  box-sizing: content-box;
  height: 1.4375em;
  margin: 0px;
  min-width: 0px;
  width: 250px;
  height: 40px;
  padding: 8.5px 14px;
}

.data-list {
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  background-color: #141414;
  margin: 0px 20px 20px 20px;
}
