.card {
  border: none;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgb(200, 200, 200);
  box-shadow: 0 0 10px rgb(200, 200, 200);
}

.profile-cover {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 200px;
  width: 100%;
  padding-top: 110px;
}

.profile-image {
  width: 100px;
  height: 100px;
}
